import(/* webpackMode: "eager" */ "/home/runner/work/lmbr-ameno/lmbr-ameno/node_modules/.pnpm/@backyard-ui+core@2.0.0-alpha.136_@types+react-dom@18.2.24_@types+react@18.2.74_csstype@3.1.3_uqhksdyerwguzn5ydu3wexhxya/node_modules/@backyard-ui/core/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/home/runner/work/lmbr-ameno/lmbr-ameno/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.4_@opentelemetry+api@1.8.0_@playwright+test@1.44.1_babel-plugin-_dliob23s2ytqpnuoagb2gc3tia/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/lmbr-ameno/lmbr-ameno/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.4_@opentelemetry+api@1.8.0_@playwright+test@1.44.1_babel-plugin-_dliob23s2ytqpnuoagb2gc3tia/node_modules/next/font/local/target.css?{\"path\":\"src/presenters/assets/fonts/index.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./regular/LeroyMerlinSans-Web-Regular.woff2\",\"style\":\"normal\",\"weight\":\"400\"},{\"path\":\"./medium/LeroyMerlinSans-Web-Medium.woff2\",\"style\":\"bold\",\"weight\":\"600\"}],\"display\":\"swap\",\"preload\":true}],\"variableName\":\"LeroyFont\"}");
;
import(/* webpackMode: "eager" */ "/home/runner/work/lmbr-ameno/lmbr-ameno/src/presenters/assets/css/main.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/lmbr-ameno/lmbr-ameno/src/presenters/layouts/partials/EmployeeNotification/EmployeeNotification.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/lmbr-ameno/lmbr-ameno/src/presenters/layouts/partials/Footer/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/lmbr-ameno/lmbr-ameno/src/presenters/layouts/partials/Header/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/home/runner/work/lmbr-ameno/lmbr-ameno/src/presenters/layouts/partials/Providers/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/lmbr-ameno/lmbr-ameno/src/presenters/layouts/partials/WhatsAppButton/WhatsAppButton.tsx");
